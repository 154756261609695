import React from 'react'
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import StatisticsCard from '../StatisticsCard/StatisticsCard';

const LSE01Chart = (props) => {
    const salinityStatus = [];
    const humidityStatus = [];
    const temperatureStatus = [];

    const salinityStatistics = {
        pointCounter: 0,
        totalValue: null,
        highest: null,
        lowest: null
    };
    const humidityStatistics = {
        pointCounter: 0,
        totalValue: null,
        highest: null,
        lowest: null
    };
    const temperatureStatistics = {
        pointCounter: 0,
        totalValue: null,
        highest: null,
        lowest: null
    };

    props.chartData["values"].map(value => {
        var temp = value[props.chartData["columns"].indexOf("temperature")];
        var salinity = value[props.chartData["columns"].indexOf("ec")];
        var humidity = value[props.chartData["columns"].indexOf("humidity")];

        var salinityObject = {
            Time: value[0],
            Salinity: salinity
        }
        var humidityObject = {
            Time: value[0],
            Humidity: humidity
        }
        var temperatureObject = {
            Time: value[0],
            Temperature: temp
        }
        if(salinityObject.Salinity != null) salinityStatus.push(salinityObject);
        if(humidityObject.Humidity != null) humidityStatus.push(humidityObject);
        if(temperatureObject.Temperature != null) temperatureStatus.push(temperatureObject);

        temperatureStatistics["pointCounter"] = temperatureStatistics["pointCounter"] + 1;
        temperatureStatistics["totalValue"] = temperatureStatistics["totalValue"] + temp;
        if(temperatureStatistics["highest"] == null || temperatureStatistics["highest"] < temp) temperatureStatistics["highest"] = temp;
        if(temperatureStatistics["lowest"] == null || temperatureStatistics["lowest"] > temp) temperatureStatistics["lowest"] = temp;

        humidityStatistics["pointCounter"] = humidityStatistics["pointCounter"] + 1;
        humidityStatistics["totalValue"] = humidityStatistics["totalValue"] + humidity;
        if(humidityStatistics["highest"] == null || humidityStatistics["highest"] < humidity) humidityStatistics["highest"] = humidity;
        if(humidityStatistics["lowest"] == null || humidityStatistics["lowest"] > humidity) humidityStatistics["lowest"] = humidity;

        salinityStatistics["pointCounter"] = salinityStatistics["pointCounter"] + 1;
        salinityStatistics["totalValue"] = salinityStatistics["totalValue"] + salinity;
        if(salinityStatistics["highest"] == null || salinityStatistics["highest"] < salinity) salinityStatistics["highest"] = salinity;
        if(salinityStatistics["lowest"] == null || salinityStatistics["lowest"] > salinity) salinityStatistics["lowest"] = salinity;

        return value;
    });
    return (
        <div>
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    data={salinityStatus}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                    <XAxis dataKey={"Time"}></XAxis>
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign="top" height={36}/>
                    <Line name="Salinity (EC)" type="monotone" dataKey={"Salinity"} stroke="#ff7300" yAxisId={0} dot={false} />
                </LineChart>
            </ResponsiveContainer>
            <StatisticsCard
            header={"Salinity Statistics"}
            min={salinityStatistics["lowest"]}
            max={salinityStatistics["highest"]}
            points={salinityStatistics["pointCounter"]}
            average={salinityStatistics["totalValue"]/salinityStatistics["pointCounter"]}
            />
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    data={humidityStatus}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                    <XAxis dataKey={"Time"}></XAxis>
                    <YAxis type="number" domain={[0, 100]}/>
                    <Tooltip />
                    <Legend verticalAlign="top" height={36}/>
                    <Line name="Humidity" type="monotone" dataKey={"Humidity"} stroke="#ff7300" yAxisId={0} dot={false} />
                </LineChart>
            </ResponsiveContainer>
            <StatisticsCard
            header={"Humidity Statistics"}
            min={humidityStatistics["lowest"]}
            max={humidityStatistics["highest"]}
            points={humidityStatistics["pointCounter"]}
            average={humidityStatistics["totalValue"]/humidityStatistics["pointCounter"]}
            />
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    data={temperatureStatus}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                    <XAxis dataKey={"Time"}></XAxis>
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign="top" height={36}/>
                    <Line name="Temperature" type="monotone" dataKey={"Temperature"} stroke="#ff7300" yAxisId={0} dot={false} />
                </LineChart>
            </ResponsiveContainer>
            <StatisticsCard
            header={"Temperature Statistics"}
            min={temperatureStatistics["lowest"]}
            max={temperatureStatistics["highest"]}
            points={temperatureStatistics["pointCounter"]}
            average={temperatureStatistics["totalValue"]/temperatureStatistics["pointCounter"]}
            />
        </div>
    );
}
export default LSE01Chart;