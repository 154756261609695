import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDeviceList } from '../../functions/rest';
import { SET_DEVICE_LIST } from '../../reducers/Devices';
import DeviceBar from '../../components/DeviceBar/DeviceBar';

import './Devices.css'

const deviceSelector = state => state.Devices;
const userSelector = state => state.User;

const Devices = () => {
    const devices = useSelector(deviceSelector);
    const user = useSelector(userSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        getDeviceList(user.token)
        .then(result => {
            dispatch({
                type: SET_DEVICE_LIST,
                payload: result
            });
        })
    }, [dispatch, user.token]);

    if(devices && devices.deviceList && devices.deviceList.length > 0) {
        return (
            <div className="deviceBarParent">
                <DeviceBar device={null} token={null} />
                {
                    devices.deviceList.map(device => {
                        return (<DeviceBar key={"id_"+ device.id} device={device} user={user} />);
                    })
                }
            </div>
        );
    }
    return "";
}

export default Devices;