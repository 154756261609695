import React, { useState } from 'react'

import "./CalendarModal.css"

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal } from 'react-bootstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const CalendarModal = (props) => {

    const [dateRange, setDateRange] = useState("Last 24 hours");

    const [showModal, setShowModal] = useState(false);

    const handleChange = (result) => {
        setShowModal(false);
        var start;
        var end;
        if(result[0] > result[1]) {
            start = result[1];
            end = result[0];
        } else {
            start = result[0];
            end = result[1];
        }
        props.onChange(start, end);
        setDateRange(formatDate(start)+"-"+formatDate(end));
    }


    const formatDate = (date) => {
        return date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
    }

    return (
        <div className={"containerDiv"}>
            Showing {dateRange} <FontAwesomeIcon icon={faCalendarAlt} onClick={() => setShowModal(true)}/>
            <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                <Modal.Title>
                    {props.title}
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Calendar
                onChange={handleChange}
                selectRange={props.selectRange}
                />
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default CalendarModal;