import React from 'react'
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useCurrentPng } from "recharts-to-png";
import PDFModal from '../PDFModal/PDFModal';
import StatisticsCard from '../StatisticsCard/StatisticsCard';

const LAIRDChart = (props) => {
    const temperatureData = [];
    const humidityData = [];
    const temperatureStatistics = {
        pointCounter: 0,
        totalValue: null,
        highest: null,
        lowest: null
    };
    const humidityStatistics = {
        pointCounter: 0,
        totalValue: null,
        highest: null,
        lowest: null
    };

    const [getTemperaturePng, { ref: tempChart }] = useCurrentPng();
    const [getHumidityPng, { ref: humidityChart }] = useCurrentPng();

    props.chartData
    .filter(value => value.field === "temperature")
    .map(value => {
        temperatureStatistics["pointCounter"] = temperatureStatistics["pointCounter"] + 1;
        temperatureStatistics["totalValue"] = temperatureStatistics["totalValue"] + value.value;
        if(temperatureStatistics["highest"] == null || temperatureStatistics["highest"] < value.value) temperatureStatistics["highest"] = value.value;
        if(temperatureStatistics["lowest"] == null || temperatureStatistics["lowest"] > value.value) temperatureStatistics["lowest"] = value.value;
        temperatureData.push(value);
    });
    props.chartData
    .filter(value => value.field === "humidity")
    .map(value => {
        humidityStatistics["pointCounter"] = humidityStatistics["pointCounter"] + 1;
        humidityStatistics["totalValue"] = humidityStatistics["totalValue"] + value.value;
        if(humidityStatistics["highest"] == null || humidityStatistics["highest"] < value.value) humidityStatistics["highest"] = value.value;
        if(humidityStatistics["lowest"] == null || humidityStatistics["lowest"] > value.value) humidityStatistics["lowest"] = value.value;
        humidityData.push(value);
    });

    return (
        <div>
            <PDFModal title="Export PDF" startTime={props.pdfStartTime} endTime={props.pdfEndTime} friendlyName={props.device.friendlyName} images={[
                {
                    key: "Temperature",
                    image: getTemperaturePng,
                    data: temperatureData
                }, 
                {
                    key: "Humidity",
                    image: getHumidityPng,
                    data: humidityData 
                }
                ]} data={[temperatureData, humidityData]}/>
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    ref={tempChart}
                    data={temperatureData}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                    <XAxis dataKey={"time"}></XAxis>
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign="top" height={36}/>
                    <Line name="Temperature" type="monotone" dataKey={"value"} stroke="#ff7300" yAxisId={0} dot={false} />
                </LineChart>
            </ResponsiveContainer>
            <StatisticsCard
            header={"Temperature Statistics"}
            min={temperatureStatistics["lowest"]}
            max={temperatureStatistics["highest"]}
            points={temperatureStatistics["pointCounter"]}
            average={temperatureStatistics["totalValue"]/temperatureStatistics["pointCounter"]}
            />
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    ref={humidityChart}
                    data={humidityData}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                    <XAxis dataKey={"time"}></XAxis>
                    <YAxis type="number" domain={[0, 100]}/>
                    <Tooltip />
                    <Legend verticalAlign="top" height={36}/>
                    <Line name="Humidity" type="monotone" dataKey={"value"} stroke="#ff7300" yAxisId={0} dot={false} />
                </LineChart>
            </ResponsiveContainer>
            <StatisticsCard
            header={"Humidity Statistics"}
            min={humidityStatistics["lowest"]}
            max={humidityStatistics["highest"]}
            points={humidityStatistics["pointCounter"]}
            average={humidityStatistics["totalValue"]/humidityStatistics["pointCounter"]}
            />
        </div>
    );
}
export default LAIRDChart;