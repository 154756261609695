import React from 'react'
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useCurrentPng } from "recharts-to-png";
import PDFModal from '../PDFModal/PDFModal';
import StatisticsCard from '../StatisticsCard/StatisticsCard'

const LHT52Chart = (props) => {
    const temperatureData = [];
    const humidityData = [];
    const temperatureStatistics = {
        pointCounter: 0,
        totalValue: null,
        highestTemp: null,
        lowestTemp: null
    };
    const humidityStatistics = {
        pointCounter: 0,
        totalValue: null,
        highestTemp: null,
        lowestTemp: null
    };

    const [getTemperaturePng, { ref: tempChart }] = useCurrentPng();
    const [getHumidityPng, { ref: humidityChart }] = useCurrentPng();

    props.chartData
    .filter(value => value.field === "temperature")
    .map(value => {
        temperatureStatistics["pointCounter"] = temperatureStatistics["pointCounter"] + 1;
        temperatureStatistics["totalValue"] = temperatureStatistics["totalValue"] + value.value;
        if(temperatureStatistics["highestTemp"] == null || temperatureStatistics["highestTemp"] < value.value) temperatureStatistics["highestTemp"] = value.value;
        if(temperatureStatistics["lowestTemp"] == null || temperatureStatistics["lowestTemp"] > value.value) temperatureStatistics["lowestTemp"] = value.value;
        temperatureData.push(value);
    });
    props.chartData
    .filter(value => value.field === "humidity")
    .map(value => {
        humidityStatistics["pointCounter"] = humidityStatistics["pointCounter"] + 1;
        humidityStatistics["totalValue"] = humidityStatistics["totalValue"] + value.value;
        if(humidityStatistics["highestTemp"] == null || humidityStatistics["highestTemp"] < value.value) humidityStatistics["highestTemp"] = value.value;
        if(humidityStatistics["lowestTemp"] == null || humidityStatistics["lowestTemp"] > value.value) humidityStatistics["lowestTemp"] = value.value;
        humidityData.push(value);
    });

    return (
        <div>
            <PDFModal title="Export PDF" startTime={props.pdfStartTime} endTime={props.pdfEndTime} friendlyName={props.device.friendlyName} images={[
                {
                    key: "Temperature",
                    image: getTemperaturePng,
                    data: temperatureData
                }, 
                {
                    key: "Humidity",
                    image: getHumidityPng,
                    data: humidityData 
                }
                ]} data={[temperatureData, humidityData]} />
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    ref={tempChart}
                    data={temperatureData}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                    <XAxis dataKey={"time"}></XAxis>
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign="top" height={36}/>
                    <Line name="Temperature" type="monotone" dataKey={"value"} stroke="#ff7300" yAxisId={0} dot={false} />
                </LineChart>
            </ResponsiveContainer>
            <StatisticsCard 
            header={"Temperature Statistics"}
            min={temperatureStatistics["lowestTemp"]}
            max={temperatureStatistics["highestTemp"]}
            points={temperatureStatistics["pointCounter"]}
            average={temperatureStatistics["totalValue"]/temperatureStatistics["pointCounter"]}
            />
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    ref={humidityChart}
                    data={humidityData}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                    <XAxis dataKey={"time"}></XAxis>
                    <YAxis type="number" domain={[0, 100]}/>
                    <Tooltip />
                    <Legend verticalAlign="top" height={36}/>
                    <Line name="Humidity" type="monotone" dataKey={"value"} stroke="#ff7300" yAxisId={0} dot={false} />
                </LineChart>
            </ResponsiveContainer>
            <StatisticsCard 
            header={"Humidity Statistics"}
            min={humidityStatistics["lowestTemp"]}
            max={humidityStatistics["highestTemp"]}
            points={humidityStatistics["pointCounter"]}
            average={humidityStatistics["totalValue"]/humidityStatistics["pointCounter"]}
            />
        </div>
    );
}
export default LHT52Chart;