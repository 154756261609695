import React from 'react'

import "./StatisticsCard.css"
import { Card } from 'react-bootstrap';

const StatisticsCard = (props) => {

    return (
        <Card>
            <Card.Header>{props.header}</Card.Header>
            <Card.Body>
                <Card.Text>
                    <table>
                        <tbody>
                            <tr>
                                <td className={"description"}>Measurement points</td>
                                <td>{props.points}</td>
                            </tr>
                            <tr>
                                <td className={"description"}>Average value</td>
                                <td>{props.average}</td>
                            </tr>
                            <tr>
                                <td className={"description"}>Highest value</td>
                                <td>{props.max}</td>
                            </tr>
                            <tr>
                                <td className={"description"}>Lowest value</td>
                                <td>{props.min}</td>
                            </tr>
                        </tbody>
                    </table>
                </Card.Text>
            </Card.Body>
        </Card>
    )
}
export default StatisticsCard;