import React, { useEffect, useState } from "react";

import "./customerbar.css"
import { Badge, Image, Navbar, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const companySelector = state => state.Company;

const CustomerBar = (props) => {
    const company = useSelector(companySelector);

    const [customerImage, setCustomerImage] = useState(null);
    
    useEffect(() => {
        if(company.logo !== null) {
            setCustomerImage(company.logo);
        }
    }, [company]);

    if(customerImage === null) return null;

    return (
        <div className="customerbar">
            <div className="customerImage">
                <Image src={`data:image/jpeg;base64,${customerImage}`} rounded />
            </div>
            <div className="textContent">
                <div className="email">
                    <Navbar>
                        <Navbar.Toggle />
                        <Navbar.Collapse>
                            <NavDropdown title={props.user.email} id="userDropdown">
                                <Link to="/user"><NavDropdown.ItemText>Account</NavDropdown.ItemText></Link>
                                <NavDropdown.Divider />
                                <Link to="/logout"><NavDropdown.ItemText>Log out</NavDropdown.ItemText></Link>
                            </NavDropdown>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </div>
            <div className="bell">
                <FontAwesomeIcon className="bellIcon" icon={faBell} />
                <Badge variant="light">0</Badge>
            </div>
        </div>
    );
}

export default CustomerBar;