import { faAt, faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { getCompany, getUserInformation, performLogin } from '../../functions/rest';
import { SET_USER } from '../../reducers/User';
import { SET_COMPANY } from '../../reducers/Company'
import './login.css';
import logo from './LogoIcon.png'

import { useToasts } from 'react-toast-notifications';
import { Link } from 'react-router-dom';

const LoginPage = () => {
    const dispatch = useDispatch();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const { addToast } = useToasts();
    
    const login = () => {
        if(username === "" || password === "") {
            addToast("Please provide a username and a password", { 
                appearance: "error"
            });
            return;
        }
        performLogin(username, password).then(token => {
            getUserInformation(token).then(userInfo => {
                dispatch({
                    type: SET_USER,
                    payload: userInfo,
                    token: token
                });
                getCompany(userInfo.sites[0].company.id, token).then(companyInfo => {
                    dispatch({
                        type: SET_COMPANY,
                        payload: companyInfo,
                        token: token
                    });
                });
            })
        });
        setPassword("");
    }

    return (
        <div className="loginForm">
            <img src={logo} alt="Company Logo" height="300" width="300"></img>

            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">
                    <FontAwesomeIcon icon={faAt}></FontAwesomeIcon>
                </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                onChange={ (event) => setUsername(event.target.value) }
                placeholder="Account e-mail"
                aria-label="Account e-mail"
                aria-describedby="basic-addon1"
                value={username}
                />
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon2">
                        <FontAwesomeIcon icon={faKey}></FontAwesomeIcon>
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl 
                onChange={ (event) => setPassword(event.target.value) }
                placeholder="Account password"
                aria-label="Account password"
                aria-describedby="basic-addon2"
                type="password"
                value={password}
                onKeyPress={(event) => { 
                    if(event.key === "Enter") {
                        login();
                    }
                }
            }
                />
            </InputGroup>

            <Button variant="primary" className="loginButton" onClick={() => {
                login();
            }}>Login</Button>
            <div className={"forgotPassword"}>
                <Link className="link" to="/resetPassword">Forgot/Reset password</Link>
            </div>
        </div>
    )
}

export default LoginPage;