import React from 'react'
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

const LNS1Chart = (props) => {
    const distanceData = [];
    const distanceTemperatureCompensatedData = [];

    props.chartData["values"].map(value => {
        var distanceObject = {
            Time: value[0],
            Distance: value[props.chartData["columns"].indexOf("Distance")]
        }
        var distanceCompensatedObject = {
            Time: value[0],
            DistanceCompensated: value[props.chartData["columns"].indexOf("DistanceTemperatureCompensated")]
        }    
        if(distanceObject.Distance != null) distanceData.push(distanceObject);
        if(distanceCompensatedObject.DistanceCompensated != null) distanceTemperatureCompensatedData.push(distanceCompensatedObject);
        return value;
    });
    return (
        <div>
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    data={distanceData}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                    <XAxis dataKey={"Time"}></XAxis>
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign="top" height={36}/>
                    <Line name="Distance" type="monotone" dataKey={"Distance"} stroke="#ff7300" yAxisId={0} dot={false} />
                </LineChart>
            </ResponsiveContainer>
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    data={distanceTemperatureCompensatedData}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                    <XAxis dataKey={"Time"}></XAxis>
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign="top" height={36}/>
                    <Line name="Distance temperature compensated" type="monotone" dataKey={"DistanceTemperatureCompensated"} stroke="#ff7300" yAxisId={0} dot={false} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}
export default LNS1Chart;