import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Button, InputGroup, Modal } from 'react-bootstrap';

import './DeviceActions.css'

const DeviceActions = (props) => {

    return (
        <Modal size="lg" show={props.visible} onHide={props.close}>
            <Modal.Header closeButton>
            <Modal.Title>{props.device.friendlyName} ({props.device.hardwareSerial}) - Actions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div>
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">
                    <FontAwesomeIcon icon={faPowerOff}></FontAwesomeIcon>
                </InputGroup.Text>
                </InputGroup.Prepend>
                <Button variant="secondary">
                Reboot
                </Button>
            </InputGroup>
            </div>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={props.close}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
    );
}
export default DeviceActions;