export const SET_COMPANY = "SET_COMPANY";

export default function reducer(state = {
    id: null,
    name: null,
    logo: null
}, action) {
    switch (action.type) {
        case SET_COMPANY:
            return {
                ...state,
                id: action.payload.id,
                name: action.payload.name,
                logo: action.payload.logo
            }
        default:
            return state;
    }
}