import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { SET_USER } from '../../reducers/User';

const Logout = () => {
    const dispatch = useDispatch();

    const logout = () => {
        dispatch({
            type: SET_USER,
            payload: {},
            token: null
        });
    }

    useEffect(() => {
        logout();
    });

    return (
        <Redirect to="/" />
    )
}

export default Logout;