export const SET_USER = "SET_USER";
export const SET_TOKEN = "SET_TOKEN";

export default function reducer(state = {
    id: null,
    roles: [],
    sites: [],
    email: null,
    contactEmails: [],
    fcmTokens: [],
    contactNumbers: [],
    created: null,
    emailAlertingEnabled: null,
    pushAlertingEnabled: null,
    smsAlertingEnabled: null,
    token: null,
    password: null
}, action) {
    switch (action.type) {
        case SET_USER:
            const userObject = action.payload;
            return {
                ...state,
                id: userObject.id,
                roles: userObject.roles,
                sites: userObject.sites,
                email: userObject.email,
                password: userObject.password,
                contactEmails: userObject.contactEmails,
                fcmTokens: userObject.fcmTokens,
                contactNumbers: userObject.contactNumbers,
                created: userObject.created,
                emailAlertingEnabled: userObject.emailAlertingEnabled,
                pushAlertingEnabled: userObject.pushAlertingEnabled,
                smsAlertingEnabled: userObject.smsAlertingEnabled,
                token: action.token
            }
        case SET_TOKEN:
            return {
                ...state,
                token: action.token
            }

        default:
            return state;
    }
}