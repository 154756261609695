import produce from "immer";

export const SET_DEVICE_LIST = "SET_DEVICE_LIST";
export const UPDATE_DEVICE = "UPDATE_DEVICE";
export const UPDATE_ALARM = "UPDATE_ALARM";

export default function reducer(state = {
    deviceList: []
}, action) {
    switch (action.type) {
        case SET_DEVICE_LIST:
            return {
                ...state,
                deviceList: action.payload
            }
        case UPDATE_DEVICE:
            const deviceUpdate = action.device;
            return produce(state, (draft) => {
                draft.deviceList.filter((device) => device.deviceId === deviceUpdate.deviceId && device.identifier === deviceUpdate.identifier)[0] = deviceUpdate;
            });
        case UPDATE_ALARM:
            const alarmUpdate = action.alarm;
            return produce(state, (draft) => {
                return draft.deviceList
                .filter((device) => device.id === alarmUpdate.deviceId)
                .filter((device) => device.alarms.filter((alarm) => alarm.identifier === alarmUpdate.identifier))[0] = alarmUpdate;
                }
            );
        default:
            return state;
    }
}