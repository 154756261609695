import React from 'react'
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

const LWS1Chart = (props) => {
    const leakStatus = [];

    props.chartData["values"].map(value => {
        var leakStatusObject = {
            Time: value[0],
            LeakStatus: value[props.chartData["columns"].indexOf("WATER_LEAK_STATUS")]
        }
        if(leakStatusObject.LeakStatus != null) leakStatus.push(leakStatusObject);
        return value;
    });
    return (
        <div>
            <ResponsiveContainer width="100%" height={400}>
                <BarChart
                    label="Leak status"
                    data={leakStatus}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                    <XAxis dataKey={"Time"}></XAxis>
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign="top" height={36}/>
                    <Bar label={"Leak status"} barSize={10} dataKey={"LeakStatus"} fill="#ff7300" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}
export default LWS1Chart;