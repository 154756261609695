import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import { verifyToken } from './functions/jwt';
import { renewToken } from './functions/rest';
import LoginPage from './pages/Login';
import Main from './pages/Main';
import ResetPasswordPage from './pages/ResetPassword';
import { SET_TOKEN } from './reducers/User';

const selectUser = state => state.User;

function App() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const iOSUrl = 'https://apps.apple.com/us/app/iotnett-sensor-app/id1499288861';
  const playUrl = 'https://play.google.com/store/apps/details?id=no.iotnett.app';

  useEffect(() => {
    if(user.token === null) return;

    const interval = setInterval(() => {
      renewToken(user.token).then(newToken => {
        if(newToken) {
          dispatch({
            type: SET_TOKEN,
            token: newToken
          });
        } else {
          dispatch({
            type: SET_TOKEN,
            token: null
          });
          return;
        }
      });
    }, 1000*60*2);
    return () => clearInterval(interval);
  }, [user.token, dispatch]);

  if(isMobile) {
    return(
      <div className="App">
        This page does not support mobile devices. Please visit your respective appstore to download our FREE, native App.<br />
        <a href={iOSUrl}>Appstore</a><br />
        <a href={playUrl}>Google play</a>
      </div>
    );
  } else {
    if(user && user.token !== null && verifyToken(user.token)) {
      return (
        <div className="App">
          <Main />
        </div>
      );
    } else {
      return (
        <div className="App">
          <Switch>
              <Route exact path="/" component={LoginPage}></Route>
              <Route exact path="/resetPassword" component={ResetPasswordPage}></Route>
          </Switch>
        </div>
      );
    }
  }
}

export default App;
