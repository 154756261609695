import { verifyToken } from "./jwt";

//const API_URL = 'http://localhost:3001/api';
const API_URL = 'https://apiv2.iotnett.no';

export function performLogin(username, password) {
    const basicAuth = btoa(`${username}:${password}`);

    const loginResult = fetch(`${API_URL}/authentication/authenticate`, {
        method: 'GET',
        
        headers: {
            "Authorization": `Basic ${basicAuth}`,
            "Content-Type":"application/json"
        }
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        // Return response as text since it's not JSON
        return response.text();
    })
    .then(data => {
        if(verifyToken(data)) {
            return data;
        }
        console.log("Failed to verify token from server, when logging in");
        return false;
    })
    .catch(error => {
        console.log("We were unable to authenticate your session. Please verify your details and try again. Error was: ");
        console.log(error);
        return false;
    } );
    return loginResult;
}

export function renewToken(token) {
    const newToken = fetch(`${API_URL}/authentication/token`, {
        method: "GET",
        headers: {
            "Content-Type":"application/json",
            "Authorization": `Bearer ${token}`
        }
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        // Return response as text since it's not JSON
        return response.text();
    })
    .then(data => {
        if(verifyToken(data)) {
            return data;
        }
        console.log("Failed to verify token when refreshing");
        return false;
    })
    .catch(error => {
        console.log("We were unable to renew your token. Please log out and try again. Error was: " + error);
        return false;
    } );
    return newToken;
}

export function downloadFile(token, path) {
    const encodedValue = encodeURIComponent(path)
    const iotFile = fetch(`${API_URL}/files/download.v2?filePath=${encodedValue}`, {
        method: "POST",
        
        headers: {
            "Content-Type":"application/json",
            "jwt":token
        }
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        console.log("Unable to retrieve file. Error was: " + error);
        return null;
    } );
    return iotFile;
}

export function getUserInformation(token) {
    const userInfo = fetch(`${API_URL}/user/`, {
        method: "GET",
        headers: {
            "Content-Type":"application/json",
            "Authorization": `Bearer ${token}`,
        }
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        console.log("Unable to retrieve user info. Error was: " + error);
        return null;
    } );
    return userInfo;
}

export function getCompany(companyId, token) {
    const company = fetch(`${API_URL}/company/${companyId}`, {
        method: "GET",
        headers: {
            "Content-Type":"application/json",
            "Authorization": `Bearer ${token}`
        }
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        console.log("Unable to retrieve company info. Error was: " + error);
        return null;
    } );
    return company;
}

export function getLastMeasurement(deviceId, token) {
    const lastMeasurement = fetch(`${API_URL}/device/${deviceId}/measurements/last`, {
        method: "GET",
        headers: {
            "Content-Type":"application/json",
            "Authorization": `Bearer ${token}`
        }
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        console.log("Unable to retrieve user info. Error was: " + error);
        return null;
    } );
    return lastMeasurement;
}

export function getMeasurements(deviceId, token, days) {
    const startTime = (Date.now()-(60000*60*24*days));
    const lastMeasurement = fetch(`${API_URL}/device/${deviceId}/measurements?startTime=${startTime}`, {
        method: "GET",
        headers: {
            "Content-Type":"application/json",
            "Authorization": `Bearer ${token}`
        }
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        console.log("Unable to retrieve user info. Error was: " + error);
        return null;
    } );
    return lastMeasurement;
}

export function getMeasurementsWithinRange(deviceId, token, startTime, endTime) {
    const lastMeasurement = fetch(`${API_URL}/device/${deviceId}/measurements?startTime=${startTime}&endTime=${endTime}`, {
        method: "GET",
        headers: {
            "Content-Type":"application/json",
            "Authorization": `Bearer ${token}`
        }
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        console.log("Unable to retrieve user info. Error was: " + error);
        return null;
    } );
    return lastMeasurement;
}

export function getDeviceList(token) {
    const deviceList = fetch(`${API_URL}/device/all`, {
        method: "GET",
        headers: {
            "Content-Type":"application/json",
            "Authorization": `Bearer ${token}`,
        }
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        console.log("Unable to retrieve devices. Error was: " + error);
        return null;
    } );
    return deviceList;
}

export function patchDevice(token, device) {
    const patchAlarm = fetch(`${API_URL}/device/${device.deviceIdentifier}`, {
        method: "PATCH",
        headers: {
            "Content-Type":"application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(device)
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        console.log("Unable to patch device. Error was: " + error);
        return null;
    } );
    return patchAlarm;
}

export function patchUser(token, user) {
    const patchUser = fetch(`${API_URL}/user/${user.email}`, {
        method: "PATCH",
        headers: {
            "Content-Type":"application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(user)
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        console.log("Unable to patch user. Error was: " + error);
        return null;
    } );
    return patchUser;
}

export function postRequestOneTimeCode(email) {
    const otcRequest = fetch(`${API_URL}/companies/user/resetPassword?email=${email}`, {
        method: "POST",
        
        headers: {
            "Content-Type":"application/json"
        }
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        console.log("Unable to request one time code. Error was: " + error);
        return null;
    } );
    return otcRequest;
}

export function patchSetPassword(email, requestContent) {
    const setPasswordRequest = fetch(`${API_URL}/companies/user/resetPassword?email=${email}`, {
        method: "PATCH",
        
        headers: {
            "Content-Type":"application/json"
        },
        body: JSON.stringify(requestContent)
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        console.log("Unable to set password. Error was: " + error);
        return null;
    } );
    return setPasswordRequest;
}