import React from 'react'
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

const LDS1Chart = (props) => {
    const openStatus = [];
    const openTimes = [];
    const openDuration = [];

    props.chartData["values"].map(value => {
        var openStatusObject = {
            Time: value[0],
            OpenStatus: value[props.chartData["columns"].indexOf("DOOR_OPEN_STATUS")]
        }
        var openTimesObject = {
            Time: value[0],
            OpenTimes: value[props.chartData["columns"].indexOf("DOOR_OPEN_TIMES")]
        }
        var openDurationObject = {
            Time: value[0],
            OpenDuration: value[props.chartData["columns"].indexOf("LAST_DOOR_OPEN_DURATION")]
        }
        if(openStatusObject.OpenStatus != null) openStatus.push(openStatusObject);
        if(openTimesObject.OpenTimes != null) openTimes.push(openTimesObject);
        if(openDurationObject.OpenDuration != null) openDuration.push(openDurationObject);
        return value;
    });
    return (
        <div>
            <ResponsiveContainer width="100%" height={400}>
                <BarChart
                    label="Open status"
                    data={openStatus}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                    <XAxis dataKey={"Time"}></XAxis>
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign="top" height={36}/>
                    <Bar label={"Open status"} barSize={10} dataKey={"OpenStatus"} fill="#ff7300" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}
export default LDS1Chart;