import React from 'react'
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useCurrentPng } from 'recharts-to-png';
import PDFModal from '../PDFModal/PDFModal';
import StatisticsCard from '../StatisticsCard/StatisticsCard';

const ExploraPMChart = (props) => {
    const temperatureData = [];
    const humidityData = [];

    const temperatureStatistics = {
        pointCounter: 0,
        totalValue: null,
        highest: null,
        lowest: null
    };
    const humidityStatistics = {
        pointCounter: 0,
        totalValue: null,
        highest: null,
        lowest: null
    };

    const [getTemperaturePng, { ref: tempChart }] = useCurrentPng();
    const [getHumidityPng, { ref: humidityChart }] = useCurrentPng();
    const [getPMChartPng, { ref: pmChart }] = useCurrentPng();

    props.chartData
    .filter(value => value.field === "temperature")
    .map(value => {
        temperatureStatistics["pointCounter"] = temperatureStatistics["pointCounter"] + 1;
        temperatureStatistics["totalValue"] = temperatureStatistics["totalValue"] + value.value;
        if(temperatureStatistics["highest"] == null || temperatureStatistics["highest"] < value.value) temperatureStatistics["highest"] = value.value;
        if(temperatureStatistics["lowest"] == null || temperatureStatistics["lowest"] > value.value) temperatureStatistics["lowest"] = value.value;
        temperatureData.push(value);
    });
    props.chartData
    .filter(value => value.field === "humidity")
    .map(value => {
        humidityStatistics["pointCounter"] = humidityStatistics["pointCounter"] + 1;
        humidityStatistics["totalValue"] = humidityStatistics["totalValue"] + value.value;
        if(humidityStatistics["highest"] == null || humidityStatistics["highest"] < value.value) humidityStatistics["highest"] = value.value;
        if(humidityStatistics["lowest"] == null || humidityStatistics["lowest"] > value.value) humidityStatistics["lowest"] = value.value;
        humidityData.push(value);
    });

    const pmDataMap = {};

    props.chartData
    .filter(value => value.field === "pm10_0" || value.field === "pm2_5" || value.field === "pm1_0")
    .forEach(value => {
        if (!pmDataMap[value.time]) {
            // Initialize an object for each unique time
            pmDataMap[value.time] = { time: value.time, PM10: null, PM2_5: null, PM1_0: null };
        }

        // Assign the correct value based on the field
        if (value.field === "pm10_0") {
            pmDataMap[value.time].PM10 = value.value;
        } else if (value.field === "pm2_5") {
            pmDataMap[value.time].PM2_5 = value.value;
        } else if (value.field === "pm1_0") {
            pmDataMap[value.time].PM1_0 = value.value;
        }
    });

    const pmData = Object.values(pmDataMap);

    return (
        <div>
            <PDFModal title="Export PDF" startTime={props.pdfStartTime} endTime={props.pdfEndTime} friendlyName={props.device.friendlyName} images={[
                {
                    key: "Temperature",
                    image: getTemperaturePng,
                    data: temperatureData
                }, 
                {
                    key: "Humidity",
                    image: getHumidityPng,
                    data: humidityData 
                }, 
                {
                    key: "Particulate Matter",
                    image: getPMChartPng,
                    data: pmData
                }
                ]} data={[temperatureData, humidityData, pmData]} />
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    ref={tempChart}
                    data={temperatureData}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                    <XAxis dataKey={"time"}></XAxis>
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign="top" height={36}/>
                    <Line name="Temperature" type="monotone" dataKey={"value"} stroke="#ff7300" yAxisId={0} dot={false} />
                </LineChart>
            </ResponsiveContainer>
            <StatisticsCard 
            header={"Temperature Statistics"}
            min={temperatureStatistics["lowest"]}
            max={temperatureStatistics["highest"]}
            points={temperatureStatistics["pointCounter"]}
            average={temperatureStatistics["totalValue"]/temperatureStatistics["pointCounter"]}
            />
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    ref={humidityChart}
                    data={humidityData}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                    <XAxis dataKey={"time"}></XAxis>
                    <YAxis type="number" domain={[0, 100]}/>
                    <Tooltip />
                    <Legend verticalAlign="top" height={36}/>
                    <Line name="Humidity" type="monotone" dataKey={"value"} stroke="#ff7300" yAxisId={0} dot={false} />
                </LineChart>
            </ResponsiveContainer>
            <StatisticsCard 
            header={"Humidity Statistics"}
            min={humidityStatistics["lowest"]}
            max={humidityStatistics["highest"]}
            points={humidityStatistics["pointCounter"]}
            average={humidityStatistics["totalValue"]/humidityStatistics["pointCounter"]}
            />
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    ref={pmChart}
                    data={pmData}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                    <XAxis dataKey={"time"}></XAxis>
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign="top" height={36}/>
                    <Line name="PM 10.0" type="monotone" dataKey={"PM10"} stroke="#ff7300" yAxisId={0} dot={false} />
                    <Line name="PM 2.5" type="monotone" dataKey={"PM2_5"} stroke="#000dff" yAxisId={0} dot={false} />
                    <Line name="PM 1.0" type="monotone" dataKey={"PM1_0"} stroke="#00fff3" yAxisId={0} dot={false} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}
export default ExploraPMChart;