import User from './User'
import Company from './Company'
import Devices from './Devices'

const reducers = {
    User,
    Company,
    Devices
}

export default reducers;