import { faComments, faHeartBroken, faShieldAlt, faSortNumericDown, faSortNumericUp } from '@fortawesome/free-solid-svg-icons';
import { Accordion, Button, Card, FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { getDeviceList, patchDevice } from '../../functions/rest';

import "./AlarmBar.css"
import { useDispatch } from 'react-redux';
import { SET_DEVICE_LIST } from '../../reducers/Devices';

import { useToasts } from 'react-toast-notifications';

const AlarmBar = (props) => {
    const dispatch = useDispatch();
    const [minValue, setMinValue] = useState(null);
    const [maxValue, setMaxValue] = useState(null);
    const [allowedViolations, setAllowedViolations] = useState(null);
    const [notificationInterval, setNotificationInterval] = useState(null);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
    const [alarmType, setAlarmType] = useState(null);

    const { addToast } = useToasts();

    useEffect(() => {
        setMinValue(props.alarm.minValue);
        setMaxValue(props.alarm.maxValue);
        setNotificationInterval(props.alarm.notificationInterval);
        setAlarmType(props.alarm.alarmType);
        setAllowedViolations(props.alarm.allowedViolations);
    }, [props.alarm]);

    useEffect(() => {
        if(
            props.alarm.minValue === minValue &&
            props.alarm.maxValue === maxValue &&
            props.alarm.notificationInterval === notificationInterval &&
            props.alarm.alarmType === alarmType &&
            props.alarm.allowedViolations === allowedViolations
        ) {
            setSaveButtonDisabled(true);
        } else {
            setSaveButtonDisabled(false);
        }
    }, [props.alarm, minValue, maxValue, notificationInterval, alarmType, allowedViolations]);

    const handleChanges = () => {
        setSaveButtonDisabled(true);
        const newAlarm = {
            ...props.alarm,
            minValue: minValue,
            maxValue: maxValue,
            notificationInterval: notificationInterval,
            alarmType: alarmType,
            allowedViolations: parseInt(allowedViolations)
        }

        const updatedAlarms = props.device.alarms.map(alarm =>
            alarm.id === newAlarm.id ? newAlarm : alarm
        );

        const updatedDevice = {
            ...props.device,
            alarms: updatedAlarms
        };

        patchDevice(props.token, updatedDevice).then((result) => {
            if(result) {
                addToast("Successfully patched alarm", { appearance: "success" });
            } else {
                addToast("Failed to patch alarm", { appearance: "error" });
            }
            getDeviceList(props.token)
            .then(result => {
                dispatch({
                    type: SET_DEVICE_LIST,
                    payload: result
                });
            })
        });
    }

    if(props.alarm == null) return null;

    const getDisplay = () => {
        let color = "displayViolations";
        if(props.alarm.allowedViolations === 0 && props.alarm.violationCounter === 0) {
            color += " greenText";
        } else if(props.alarm.violationCounter > 0 && props.alarm.violationCounter < props.alarm.allowedViolations) {
            color += " orangeText";
        } else if(props.alarm.violationCounter >= props.alarm.allowedViolations) {
            color += " redText";
        } else {
            color += " greenText";
        }
        return (
            <div className="displayBar">
                <div className="displayIdentifier">
                    {props.alarm.identifier}
                </div>
                <div className={color}>
                    ({props.alarm.violationCounter}/{props.alarm.allowedViolations} violations)
                </div>
            </div>
        );
    }

    return (
        <Accordion>
            <Card>
                <Card.Header>
                    <Accordion.Toggle className="accordionHeader" as={Card.Header} variant="link" eventKey={props.alarm.identifier}>
                        {
                            getDisplay()
                        }
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={props.alarm.identifier}>
                    <Card.Body>
                        <InputGroup className="mb-1">
                            <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                                <FontAwesomeIcon icon={faSortNumericUp}></FontAwesomeIcon>
                            </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="Minimum value"
                                aria-label="Minimum value"
                                aria-describedby="basic-addon1"
                                value={minValue !== null ? minValue : ""}
                                onChange={(event) => setMinValue(event.target.value !== "" ? event.target.value : null)}
                            />
                            <InputGroup.Append>
                            <InputGroup.Text className="customFooter" id="basic-addon1">
                                Min
                            </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                        <InputGroup className="mb-1">
                            <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                                <FontAwesomeIcon icon={faSortNumericDown}></FontAwesomeIcon>
                            </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="Maximum value"
                                aria-label="Maximum value"
                                aria-describedby="basic-addon1"
                                value={maxValue !== null ? maxValue : ""}
                                onChange={(event) => setMaxValue(event.target.value !== "" ? event.target.value : null)}
                            />
                            <InputGroup.Append>
                            <InputGroup.Text className="customFooter" id="basic-addon1">
                                Max
                            </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                        <InputGroup className="mb-1">
                            <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                                <FontAwesomeIcon icon={faShieldAlt}></FontAwesomeIcon>
                            </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="Notification interval"
                                aria-label="Notification interval"
                                aria-describedby="basic-addon1"
                                value={notificationInterval||''}
                                onChange={(event) => setNotificationInterval(event.target.value !== "" ? event.target.value : 1800)}
                            />
                            <InputGroup.Append>
                            <InputGroup.Text className="customFooter" id="basic-addon1">
                                seconds
                            </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                        <InputGroup className="mb-1">
                            <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                                <FontAwesomeIcon icon={faComments}></FontAwesomeIcon>
                            </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                as="select"
                                placeholder="Alert type"
                                aria-label="Alert type"
                                aria-describedby="basic-addon1"
                                onChange={ (event) => setAlarmType(event.target.value) }
                                value={alarmType||''}
                            >
                                <option value="ALL">ALL</option>
                                <option value="PUSH">PUSH</option>
                                <option value="SMS">SMS</option>
                                <option value="EMAIL">EMAIL</option>
                            </FormControl>
                            <InputGroup.Append>
                            <InputGroup.Text className="customFooter" id="basic-addon1">
                                type
                            </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                        <InputGroup className="mb-1">
                            <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                                <FontAwesomeIcon icon={faHeartBroken}></FontAwesomeIcon>
                            </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="Allowed violation count"
                                aria-label="Allowed violation count"
                                aria-describedby="basic-addon1"
                                value={allowedViolations||''}
                                onChange={(event) => setAllowedViolations(event.target.value !== "" ? event.target.value : 0)}
                            />
                            <InputGroup.Append>
                            <InputGroup.Text className="customFooter" id="basic-addon1">
                                Violations
                            </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                        <Button disabled={saveButtonDisabled} variant="success" onClick={handleChanges}>
                            Save
                        </Button>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}

export default AlarmBar;